import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import AmortizationSchedule from '../components/amortization-schedule'
import ChunkingSchedule from '../components/chunking-schedule'
import EquityAndInterestGraph from '../components/equity-and-interest-graph'

const VelocityBankingWithAHelocPage: React.FC = () => (
  <Layout>
    <SEO title="Velocity Banking with a HELOC" />
    <h1>Velocity Banking with Cryptocurrency with a HELOC</h1>

    <p>
      Velocity banking is a debt payment acceleration strategy using your
      existing cashflow to efficiently pay off your home loan in a shorter
      period of time. Aggressively using this strategy can allow you to pay off
      a standard 30 year mortgage in 5-7 years.
    </p>

    <div>
      <AmortizationSchedule />
      <ChunkingSchedule />
      <EquityAndInterestGraph />
    </div>
  </Layout>
)

export default VelocityBankingWithAHelocPage
